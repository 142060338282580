import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import ContactForm from '../components/ContactForm/ContactForm';
import '../styles/contact.scss';
import { Helmet } from 'react-helmet';

function Contact(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const style = {
    position: 'relative',
    width: '100%',
    height: '400px'
  };

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Contact Us</title>
        <meta name='description' content='Contact HortPlus' />
      </Helmet>
      <HeaderExpanded
        title='Contact'
        image={
          process.env.GATSBY_S3_BUCKET_ASSETS +
          'images/orchard_mountain_range.jpg'
        }
      />
      <Container className='text-center mt-5 mb-2'>
        <Row>
          <Col className='text-center'>
            <p className='text-dark hp-font-42'>Get in Touch</p>
            <a
              className='text-dark hp-font-22'
              href='mailto:support@hortplus.com?subject=HortPlus'
            >
              <b>support@hortplus.com</b>
            </a>
          </Col>
        </Row>
      </Container>
      <ContactForm />
      <Container className='hp-divider' />
      <Container className='py-5 d-flex flex-column justify-content-center align-items-center'>
        <header className='hp-title--subtitle-half d-block text-center hp-bold-weight pb-3'>
          Office Locations
        </header>
        <Row noGutters>
          <Col className='hp-page--blue-grey m-3'>
            <Map
              google={props.google}
              initialCenter={{
                lat: -41.282896301887334,
                lng: 174.777598255194
              }}
              zoom={18}
              containerStyle={style}
              className='hp-map-card'
            >
              <Marker
                title={'HortPlus, Wellington'}
                name={'HortPlus, Wellington'}
                position={{ lat: -41.282896301887334, lng: 174.777598255194 }}
              />
            </Map>
            <header className='hp-title--subtitle-half hp-bold-weight py-3 pl-5'>
              Wellington
            </header>
            <p className='pl-5 pb-3'>
              <b>HortPlus</b>
              <br /> Level 1 Aviation House,
              <br /> 12 Johnston Street,
              <br /> Wellington 6011,
              <br /> New Zealand
            </p>
          </Col>
          <Col className='hp-page--blue-grey m-3'>
            <Map
              google={props.google}
              initialCenter={{
                lat: -39.6245496,
                lng: 176.8786344
              }}
              zoom={15}
              containerStyle={style}
              className='hp-map-card'
            >
              <Marker
                title={'HortPlus, Hawkes Bay'}
                name={'HortPlus, Hawkes Bay'}
                position={{ lat: -39.6245496, lng: 176.8786344 }}
              />
            </Map>
            <header className='hp-title--subtitle-half hp-bold-weight pl-5 py-3'>
              Hawkes Bay
            </header>
            <p className='pl-5 pb-3'>
              <b>HortPlus</b>
              <br />
              21 Ruahapia Road,
              <br />
              RD 10 Waipatu,
              <br />
              Hastings 4180,
              <br />
              New Zealand
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// This prevents the default text 'Loading...' from showing
const LoadingContainer = () => <></>;

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_API_KEY,
  LoadingContainer: LoadingContainer
})(Contact);
