import React, { useState } from 'react';
import {
  Alert,
  Form,
  Button,
  Container,
  Row,
  Col,
  FormGroup
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const FAKE_GATEWAY_URL =
  'https://hwmp0oxn48.execute-api.ap-southeast-2.amazonaws.com/production';
const required = 'This field is required';

export default () => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await fetch(FAKE_GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        'submit',
        'submitError',
        `Oops! There seems to be an issue! ${error.message}`
      );
    }
  };

  const showSubmitError = (msg) => <Alert variant='warning'>{msg}</Alert>;

  const showThankYou = (
    <Container>
      <Row className='my-3 justify-content-center'>
        <Col md={8} className="align-content-center">
          <Alert variant='success' className='pb-3 text-center'>
            Your message has been received. We will be in touch shortly.
          </Alert>
        </Col>
      </Row>
    </Container>
  );

  const showForm = (
    <Container>
      <Row className='justify-content-center my-3'>
        <Col md={6}>
          <Form onSubmit={handleSubmit(onSubmit)} method='post'>
            <Form.Group>
              <Form.Label>Your Email</Form.Label>
              <Form.Control
                type='email'
                name='email'
                id='email'
                placeholder='Your Email Address'
                ref={register({ required })}
                disabled={isSubmitting}
              />
              {errors.email && (
                <Alert variant='warning mt-1'>{errors.email.message}</Alert>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type='text'
                name='name'
                id='name'
                placeholder='Your name'
                ref={register({ required })}
                disabled={isSubmitting}
              />
              {errors.name && (
                <Alert variant='warning mt-1'>{errors.name.message}</Alert>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                name='phone'
                id='phone'
                placeholder='Your Phone Number'
                ref={register({ required })}
                disabled={isSubmitting}
              />
              {errors.phone && (
                <Alert variant='warning mt-1'>{errors.phone.message}</Alert>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as='textarea'
                name='comment'
                id='comment'
                rows={5}
                ref={register({ required })}
                placeholder='Comment'
              />
              {errors.comment && (
                <Alert variant='warning mt-1'>{errors.comment.message}</Alert>
              )}
            </Form.Group>

            <FormGroup className='text-center'>
              <Button variant='primary' type='submit' disabled={isSubmitting}>
                Send Message
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div className='page contact-page'>
      <div className='text-side'>
        {errors && errors.submit && showSubmitError(errors.submit.message)}
      </div>
      <div className='form-side'>{submitted ? showThankYou : showForm}</div>
    </div>
  );
};
